function trackEventOnLoaded(name, params) {
  window.KidAnalytics.addOnLoaded(() => trackEvent(name, params));
}

function trackEvent(name, params) {
  params = params || {};

  // google analytics
  if (window.ga && !params.skipGoogleAnalytics) {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'Engagement',
      eventAction: name
    });
  }

  // Mixpanel
  if (window.mixpanel && !params.skipMixpanel) {
    var properties = params.parameters || {};
    properties.platform = 'web';
    properties.page = window.normalizedPage;
    properties.referrer = window.normalizedReferringPage;
    window.mixpanel.track(name, properties);
  }

  // Track event in Google Tag Manager
  if (dataLayer) {
    dataLayer.push({'event': name});
  }
}

function alias(userId) {
  if (window.mixpanel) {
    window.mixpanel.alias("" + userId);
  }
}

function identify(userId, shopName, email, subdomain) {
  if (window.mixpanel) {
    window.mixpanel.people.set({name: shopName, email: email, subdomain: subdomain});
    window.mixpanel.identify("" + userId);
  }

  klaviyo.push([
    'identify',
    {
      external_id: userId,
      email: email
    }
  ])
}

const KidAnalytics = {
  // Keeps track of whether the analytics library has loaded or not.
  _hasAnalyticsLoaded: false,

  // Tracks the functions that need to be called once the analytics library has loaded.
  _onLoadedFunctions: [],

  // Add a function to be called once the analytics library is loaded. The first argument passed to the function will
  // be the loaded library's instance. You'll likely want to call this on the KidAnalytics in the window
  // (window.KidAnalytics) otherwise your function may never be called.
  addOnLoaded: function (new_function) {
    // Only allow functions to be added to the queue;
    if (typeof new_function != "function") {
      return;
    }

    // If the analytics library has loaded, call the function now.
    if (this._hasAnalyticsLoaded) {
      new_function(window.mixpanel);
    } else {
      // If the analytics library has not loaded, add it to the queue of functions to be called once it's loaded.
      this._onLoadedFunctions.push(new_function);
    }
  },

  // Call this when the analytics library has loaded. It will call each function in the _onLoadedFunctions.
  analyticsLoaded: function (mixpanel) {
    this._hasAnalyticsLoaded = true;
    this._onLoadedFunctions.forEach((func) => func(mixpanel));
  },

  // Use these constants to track a user's sign up method.
  signUpType: {
    EMAIL: 'Email',
    FACEBOOK: 'Facebook',
    GOOGLE: 'Google',
    APPLE: 'Apple'
  },

  checkoutError: {
    ADDRESS: 'address',
    UNKNOWN: 'unknown'
  },

  shareType: {
    COLLECTION: 'collection',
    UNKNOWN: 'unknown'
  },

  shareMethod: {
    FACEBOOK: 'facebook',
    PINTEREST: 'pinterest',
    EMAIL: 'email',
    COPY: 'copy',
  },

  filterType: {
    SAVED: 'saved',
    TRANSIENT: 'transient'
  },

  // Seconds-since-epoch timestamp for mixpanel event deduplication. Set in _mixpanel.erb
  renderTimestamp: 0,

  trackPinterestEvent(eventName) {
    if (!window.pintrk) return

    window.pintrk('track', eventName)
  },

  // Takes a function that will be called with the anonymous id as it's first argument once the analytics library has loaded.
  anonymousId: function (callback) {
    if (window.mixpanel) {
      this.addOnLoaded((mixpanel) => callback(mixpanel.get_distinct_id()));
    } else {
      callback("");
    }
  },

  // Call this every time a user signs up, logs in, or changes their information.
  identify: function (userId, shopName, email, subdomain) {
    identify(userId, shopName, email, subdomain);
  },

  onLogOut: function () {
    if (window.mixpanel) {
      window.mixpanel.reset();
    }
  },

  // Call this when a user taps the sign up button (Facebook or Email).
  trackSignUpStarted: function (type) {
    type = type || this.signUpType.EMAIL;
    trackEvent('Sign Up Started', {
      parameters: {source: type.toLowerCase()}
    });
  },

  // Call this when a user's sign up is completed successfully.
  trackSignUpCompleted: function (userId, shopName, email, type) {
    alias(userId);
    type = type || this.signUpType.EMAIL;
    trackEvent('Sign Up Completed', {
      parameters: {source: type.toLowerCase()}
    });

    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-975698521/aXewCMe6gsQBENn0n9ED'
      });
    }

    if (window.fbq) {
      window.fbq('track', 'CompleteRegistration', {
        value: 0,
        currency: 'USD',
      });
    }
  },

  // Call this when a user experiences a sign up error.
  trackSignUpError: function () {
    trackEvent('Sign Up Error');
  },

  // Call this when a user adds an item to their cart.
  trackAddListingToCart: function (parameters) {
    trackEvent('Product Added', {parameters: parameters});

    this.trackPinterestEvent('addtocart');
  },

  // Call this when a user taps the button to checkout.
  trackCheckoutStarted: () => {
    trackEvent("Checkout Started")

    // Klaviyo 'Started Checkout' event is sent from CheckoutStartedWorker
  },

  trackFirstOrderCheckoutStarted: () => trackEvent("First Order Checkout Started"),

  // Call this when a user has successfully completed checkout.
  //
  // Expected format or order param:
  // {
  //   id: 1,
  //   totalCents: 1000,
  //   numSellers: 1,
  //   numItems:   1,
  //   orders: [{
  //     id: 1,
  //     shopName: "Violet's closet",
  //     totalCents: 1000,
  //     totalTaxCents: 100,
  //     items: [{
  //       id: "333",
  //       name: "Matilda Jane Jacket",
  //       category: "Clothing",
  //       amountCents: 1000
  //   }]
  // }
  trackCheckoutCompleted: function (checkout, userInfo) {
    let totalCents = checkout.totalCents || 0;
    let totalDollars = totalCents / 100.0;
    let items = checkout.orders.flatMap(order => order.items);

    trackEvent("Order Completed", {
      parameters: {
        total: totalDollars,
        revenue: totalDollars,
        totalCents: totalCents,
        currency: "USD",
        numSellers: checkout.numSellers,
        numItems: checkout.numItems,
        time: KidAnalytics.renderTimestamp,
        $insert_id: `order-completed-${checkout.id}`
      }
    });

    this._trackGoogleAnalyticsCheckoutCompleted(checkout);
    this._trackAdwordsCheckoutCompleted(checkout);
    this._trackFacebookCheckoutCompleted(totalDollars);
    this._trackPinterestCheckoutCompleted(checkout, items);
    this._trackImpactCheckoutCompleted(checkout, items, userInfo);
  },

  _trackGoogleAnalyticsCheckoutCompleted(checkout) {
    if (!window.ga) return

    for (let i = 0; i < checkout.orders.length; i++) {
      let order = checkout.orders[i];
      let totalDollars = order.totalCents / 100.0;
      let totalTaxDollars = order.totalTaxCents / 100.0;

      window.ga("ecommerce:addTransaction", {
        "id": order.id.toString(),
        "affiliation": order.shopName.toString(),
        "revenue": totalDollars,
        "shipping": 0,
        "tax": totalTaxDollars
      })

      for (let j = 0; j < order.items.length; j++) {
        let item = order.items[j];

        let amountCents = item.amountCents || 0;
        let amountDollars = amountCents / 100.0;

        window.ga("ecommerce:addItem", {
          "id": order.id.toString(),
          "name": item.name.toString(),
          "sku": item.id.toString(),
          "category": item.category.toString(),
          "price": amountDollars,
          "quantity": 1
        });
      }
    }

    window.ga("ecommerce:send");
  },

  _trackAdwordsCheckoutCompleted(checkout) {
    if (!window.gtag) return

    for (let i = 0; i < checkout.orders.length; i++) {
      let order = checkout.orders[i]
      window.gtag('event', 'conversion', {
        'send_to': 'AW-975698521/o3PwCMf8psMBENn0n9ED',
        'value': 1.0,
        'currency': 'USD',
        'transaction_id': order.id.toString()
      });
    }
  },

  _trackFacebookCheckoutCompleted(totalDollars) {
    if (!window.fbq) return

    window.fbq('track', 'Purchase', {
      value: totalDollars,
      currency: 'USD',
    });
  },

  _trackPinterestCheckoutCompleted(checkout, items) {
    let totalCents = checkout.totalCents || 0;
    let totalDollars = totalCents / 100.0;

    let line_items = items.map(item => {
      let amountDollars = item.amountCents / 100.0;

      return {
        'product_name': item.name,
        'product_category': item.category,
        'product_id': item.id,
        'product_price': amountDollars,
        'product_quantity': 1
      }
    })

    window.pintrk('track', 'checkout', {
      order_id: checkout.id,
      value: totalDollars,
      currency: 'USD',
      order_quantity: checkout.numItems,
      line_items: line_items
    })
  },

  _trackImpactCheckoutCompleted(checkout, items, userInfo) {
    let line_items = items.map(item => ({
      subTotal: item.amountCents / 100.0,
      category: item.category,
      sku: item.id.toString(),
      quantity: 1,
      name: item.name.substring(0, 128)
    }));

    ire(
      "trackConversion",
      43146,
      {
        orderId: `checkout_${checkout.id}`,
        customerId: userInfo.id.toString(),
        customerEmail: userInfo.emailSha1,
        customerStatus: userInfo.customerStatus,
        currencyCode: "USD",
        orderPromoCode: "",
        orderDiscount: checkout.totalDiscountCents / 100.0,
        items: line_items
      },
      { verifySiteDefinitionMatch: true }
    );
  },

  trackFirstCheckoutCompleted(checkout) {
    let totalCents = checkout.totalCents || 0;
    let totalDollars = totalCents / 100.0;

    trackEvent("First Order Completed", {
      parameters: {
        total: totalDollars,
        revenue: totalDollars,
        totalCents: totalCents,
        currency: "USD",
        numSellers: checkout.numSellers,
        numItems: checkout.numItems,
        time: KidAnalytics.renderTimestamp,
        $insert_id: `first-order-completed-${checkout.id}`
      }
    });
  },

  // Call this when a user experiences an error checking out.
  trackCheckoutError(type) {
    type = type || this.checkoutError.UNKNOWN;

    trackEvent("Checkout Error", {
      parameters: {type: type}
    });
  },

  trackFirstOrderCheckoutError(type) {
    type = type || this.checkoutError.UNKNOWN;

    trackEvent("First Order Checkout Error", {
      parameters: {type: type}
    });
  },

  // Call this when a user clicks the share button (Facebook).
  trackContentShared: function (type, method) {
    type = type || this.shareType.UNKNOWN;
    method = method || this.shareMethod.FACEBOOK;
    trackEvent('Content Shared', {
      parameters: {type: type, method: method}
    });
  },

  // Call this every time the user filters a result set
  trackFilter: function (brands, filterType, sizes, categories, genderKey, hashtagKey) {
    let parameters = {
      type: filterType || this.filterType.TRANSIENT,
      brands: brands || [],
      sizes: sizes || [],
      categories: categories || [],
      gender: genderKey,
      hashtag: hashtagKey
    };
    trackEvent('Filter', { parameters: parameters });
  },

  // Call this every time the user searches a result set
  trackSearch: function () {
    trackEvent('Search');
  },

  // Call this every time a buyer creates an offer
  trackBuyerSentOffer: function () {
    trackEvent('Buyer Sent Offer');
  },

  // Call this every time the user creates a collection
  trackCreateCollection: function () {
    trackEvent('Create Collection Completed');
  },

  // call this every time a user views a collection
  // currently GA only since it is free and there will be a lot of events
  trackCollectionViewed: function () {
    trackEvent('Collection Viewed', {
      skipMixpanel: true
    });
  },

  // call this every time the user taps the SELL button to list
  trackCreateListingStarted: function () {
    trackEvent('Create Listing Started');
    klaviyo.push(['track', 'Started Create Listing'])
  },

  // Call this when the user experiences an error listing an item
  trackCreateListingError: function () {
    trackEvent('Create Listing Error');
  },

  // call this every time the user creates a listing
  trackCreateListingCompleted() { trackEvent("Create Listing Completed") },

  trackShopHomeFeedStarted() { trackEvent("Shop Home Feed Started") },

  trackShopHomeFeedCompleted() { trackEvent("Shop Home Feed Completed") },

  trackNewBuyerPromoShown() { trackEvent("New Buyer Promo Shown") },

  trackNewBuyerPromoNotShown() { trackEvent("New Buyer Promo Not Shown") },

  trackFindStyleScoutStarted() { trackEventOnLoaded("Find Style Scout Started") },

  // city is optional
  trackFindStyleScoutCompletedWithResults(state, city) {
    trackEventOnLoaded(
      "Find Style Scout Completed With Results",
      {parameters: {state, city}}
    )
  },

  // city is optional
  trackFindStyleScoutCompletedWithNoResults(state, city) {
    trackEventOnLoaded(
      "Find Style Scout Completed With No Results",
      {parameters: {state, city}}
    )
  },

  // city is optional
  setUpTrackStartMessageToStyleScout(querySelector, state, city, loggedIn) {
    this.addOnLoaded((mixpanel) => {
      if (!mixpanel) return;
      mixpanel.track_links(
        querySelector,
        "Start Message To Style Scout",
        {state, city, loggedIn, platform: "web"}
      );
    });
  },

  setUpTrackBannerSetClick(querySelector, bannerSetName, bannerSetPosition, bannerName) {
    this.addOnLoaded((mixpanel) => {
      if (!mixpanel) return;

      mixpanel.track_links(
        querySelector,
        "Banner Clicked",
        {bannerSetPosition, bannerSetName, bannerName, platform: "web"}
      )
    });
  },

  trackMessageCompleted() { trackEventOnLoaded("Direct Message Completed"); },

  trackPromotingListings(dailyBudget) {
    trackEventOnLoaded("Promoting Listings", { "dailyBudgetCents": dailyBudget })
  },

  trackDeactivatedPromotingListings() {
    trackEventOnLoaded("Deactivated Promoting Listings")
  }
};

export default KidAnalytics;
